<template>
  <div>

    <b-row>

      <b-col
        v-for="plano in planos"
        xl="4" cols="12"
      >
        <b-card 
          class="justify-content-center align-items-center text-center"
        >

          <b-card-body>

            <div>
              <b-badge
                variant="dark"
                pill
              >
                {{ plano.nome }}
              </b-badge>
            </div>

            <div>

              <sup 
              class="font-weight-bold text-primary" 
              style="top: -1.5rem;left: 0;font-size: 1.1rem !important;"
              >
                R$
              </sup>
              <span class="font-weight-bolder text-primary" style="font-size: 3.5rem;">
                {{ Math.floor(plano.valor / 100)}}
              </span>
              <sup 
              class="font-medium-1 font-weight-bold text-primary"
              style="top: -1.5rem;left: -.5rem;font-size: 1.1rem !important;"
              >
                ,{{ ('0' + plano.valor % 100).slice(-2) }}
              </sup>
              
            </div>

            <div>
              <span class="font-weight-bold">
                <b-icon
                  font-size="15"
                  icon="wallet2"
                  color="#000"
                  style="margin-right: 3px;"
                />
                {{plano.credito}} créditos
              </span>
            </div>

            <b-button
              class="mt-1"
              variant="primary"
              :to="{ name: 'financeiro-recarregar-credito-id', params: { id: plano.id } }"
            >
              Selecionar Plano
            </b-button>
            
          </b-card-body>
           
        </b-card>
      </b-col>

      

    </b-row>

  </div>
</template>

<script>
import {
  BRow, BImg, BCol, BCard, BCardBody, BCardFooter, BForm, BFormGroup, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BButton, BBadge, BLink, BSpinner,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BBadge,
    BLink,
    BSpinner,
  },
  data() {
    return {

      planos: [],
      carregamentoApi: false,
     
    }
  },
  created() {
    this.planosLista()
  },
  methods: {

    planosLista() {
      this.carregamentoApi = true

      useJwt.assinaturaPlanosLista({
        page: this.page,
        size: this.size,
        sort: this.sort,
      })
        .then(response => {

          this.planos = response.data.content

          // console.log(this.planos)
       
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
//@import '@fracto/vue-credit-card/';

.card.selecionarPagamento:hover {
  box-shadow: 0 0 5px 1px rgb(254 152 86 / 40%) !important;
  border-color: #ff7300 !important;
}


</style>
